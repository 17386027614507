import { Routes, Route  } from "react-router-dom";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import TaskBar from "./Components/TaskBar";
import Staking from "./Pages/Staking";
import SideBar from "./Components/SideBar";
import { ProSidebarProvider } from "react-pro-sidebar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#36c",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#9e9e9e",
      contrastText: "#ffffff",
    },
  },
});

function App() {
  

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ProSidebarProvider>
          <ThirdwebProvider >
            <SnackbarProvider maxSnack={3} classes={{containerRoot: 'snackbar'}}>
                <SideBar />
                <main>
                  <TaskBar />
                  <Routes>
                    <Route exact path="/" element={<Staking />} />
                  </Routes>
                </main>
            </SnackbarProvider>
          </ThirdwebProvider>
        </ProSidebarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
