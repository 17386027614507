import "./countdown.css";

const Countdown = ({ countdownData }) => {
    return (
        <div className='countdown-wrapper'>
          <div className='countdown-box'>
            <span className='legend'>{countdownData.days}</span>
            <span className='legend2'>Days</span>
          </div>
          <div className='countdown-box'>
            <span className='legend'>{countdownData.hours}</span>
            <span className='legend2'>Hours</span>
          </div>
          <div className='countdown-box'>
            <span className='legend'>{countdownData.minutes}</span>
            <span className='legend2'>Minutes</span>
          </div>
          <div className='countdown-box'>
            <span className='legend'>{countdownData.seconds}</span>
            <span className='legend2'>Seconds</span>
          </div>
        </div>
    );
};

export default Countdown;