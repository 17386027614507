import React, { useEffect, useContext } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import "./index.css";
import {
    Home,
    Description,
    Telegram,
    Twitter,
    Article,
    ShoppingCart,
    KeyboardArrowRight,
    LockClock,
    HeadsetMic
} from "@material-ui/icons";
import { Badge } from './Badge.tsx';

let location = {
    pathname: null
};

function SideBar(props) {
    const {collapsed, toggleSidebar} = useProSidebar();
    const [activeIndex, setActiveIndex] = React.useState(-1);
    location = useLocation();

    const handleNavClick = () => {
        toggleSidebar();
    };

    useEffect(() => {
        const initialIndex = location.pathname === '/' ? 0 
        : -1; 

        if (initialIndex > -1) {
            setActiveIndex(initialIndex);
        }
    }, [location]);

    return (
        <Sidebar backgroundColor="#2d2d2d9e" breakPoint="lg" style={{ height: "100vh", margin: "0px", padding: "0px" }}>
            <Menu>
                <div className="logo-area">
                    {collapsed ? <img alt='' src='icon.png' height="50px"/> : <img alt='' src='logo.png' height="50px"/> }
                </div>
                <div style={{ padding: '0 24px', marginBottom: '8px' }}>
                    <Typography
                        variant="body2"
                        fontWeight={600}
                        style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px', color: "#fff", paddingBottom: "10px" }}
                    >
                        Navigation
                    </Typography>
                </div>
                <a href="https://solalgo.com/" className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 99} icon={<Home />}>
                        <span>Home</span>
                    </MenuItem>
                </a>
                <Link to='/' className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 0} icon={<LockClock />}>
                        <span>Staking <Badge variant="info" className="whiteTxt">New</Badge></span>
                    </MenuItem>
                </Link>
                <div style={{ padding: '0 24px', marginBottom: '8px' }}>
                    <Typography
                        variant="body2"
                        fontWeight={600}
                        style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px', color: "#fff", paddingBottom: "10px", marginTop:"20px" }}
                    >
                        Socials
                    </Typography>
                </div>
                <a href='https://t.me/solago_slgo' target="_blank" className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 99} icon={<Telegram />} suffix={<KeyboardArrowRight/>}>
                        <span>Telegram</span>
                    </MenuItem>
                </a>
                <a href='https://medium.com/@solalgo' target="_blank" className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 99} icon={<Article />} suffix={<KeyboardArrowRight/>}>
                        <span>Medium</span>
                    </MenuItem>
                </a>
                <a href='https://discord.gg/TBpAUJbG' target="_blank" className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 99} icon={<HeadsetMic />} suffix={<KeyboardArrowRight/>}>
                        <span>Discord</span>
                    </MenuItem>
                </a>
                <a href='https://twitter.com/SolalgoSLGO' target="_blank" className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 99} icon={<Twitter />} suffix={<KeyboardArrowRight/>}>
                        <span>Twitter</span>
                    </MenuItem>
                </a>
                <div style={{ padding: '0 24px', marginBottom: '8px' }}>
                    <Typography
                        variant="body2"
                        fontWeight={600}
                        style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px', color: "#fff", paddingBottom: "10px", marginTop:"20px" }}
                    >
                        Links
                    </Typography>
                </div>
                <a href='https://solalgo.com/static/media/Solalgowhite.5ba2dcffd853f7c7d0a3.pdf' target="_blank" className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 5} icon={<Description />} suffix={<KeyboardArrowRight/>}>
                        <span>Whitepaper</span>
                    </MenuItem>
                </a>
                <a href='https://sol.solalgo.com/' target="_blank" className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 99} icon={<ShoppingCart />} suffix={<KeyboardArrowRight/>}>
                        <span>Buy Now</span>
                    </MenuItem>
                </a>
            </Menu>
        </Sidebar>
    );
}
    
export default SideBar;