import React, { Fragment, useState, useEffect } from 'react';
import { 
    Container,
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import { ethers } from "ethers";
import { useSnackbar } from "notistack";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import Countdown from '../../Components/Countdown';
import * as solalgo from "../../Constants/solalgo";
import "./index.css";

let connectedAddress = null;
let signer = null;
let stakingContract = null;
let slgoContract = null;

export default function Staking(props) {
    const styles = (theme) => ({
        mainContainer: {
            marginBottom: 25
        },
        paperContainer: {
            padding: theme.spacing(5),
            paddingBottom: theme.spacing(3),
            background: "#3f3f3f69!important",
            borderRadius: "15px",
            textAlign: "center",
        },
        tierContainer: {
            padding: theme.spacing(5),
            paddingBottom: 25,
            background: "#3f3f3f69!important",
            borderRadius: "15px",
            textAlign: "center",
            minHeight: 947
        },
        rewardContainer: {
            padding: theme.spacing(5),
            paddingBottom: theme.spacing(3),
            background: "#3f3f3f69!important",
            borderRadius: "15px",
            textAlign: "center",
            marginTop: 25,
            minHeight: 500
        },
        fullWidth: {
            width: "100%",
        },
        hr: {
            width: "100%",
        },
        title: {
            textAlign: "left",
            display: "block",

        },
        statisticContainer: {
            marginTop: 15,
            padding: 15,
            borderRadius: "15px",
        },
        statistic: {
            border: "1px solid #575757",
            borderRadius: 15,
            padding: 15
        },
        inputContainer: {
            padding: 15
        },
        unlockContainer: {
            marginTop: 15,
            paddingTop: 15,
            borderTop: "1px solid #575757"
        },
        timerContainer: {
            display: "flex",
            justifyContent: "space-around"
        },
        smallTxt: {
            fontSize: "10px!important"
        },
        smallTxt: {
            fontSize: "12px!important"
        }
    });

    const { enqueueSnackbar } = useSnackbar();
    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const [isApproving, setIsApproving] = useState(false);
    const [isDepositing, setIsDepositing] = useState(false);
    const [isWithdrawing, setIsWithdrawing] = useState(false);
    const [isClaimingRewards, setisClaimingRewards] = useState(false);
    const [isEmWithdrawing, setIsEmWithdrawing] = useState(false);
    const [depositAmount, setDepositAmount] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState(0);
    const [slgoBalance, setSLGOBalance] = useState(0);
    const [apr, setAPR] = useState(null);
    const [currentTier, setCurrentTier] = useState(0);
    const [totalEarned, setTotalEarned] = useState(-1);
    const [unlockTime, setHolderUnlockTime] = useState(0);
    const [pendingReward, setPendingReward] = useState(0);
    const [dividendBalance, setDividendBalance] = useState(0);
    const [pendingDividend, setPendingDividend] = useState(0);
    const [exitPenalty, setExitPenalty] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const [unlockState, setUnlockState] = useState({
        seconds: 0,
        hours: 0,
        minutes: 0,
        days: 0,
        isUnlocked: false,
        initalised: false
    });

    const depositSLGO = async() => {
        if (!signer) {
            notify(0, "Please connect your wallet first");
            return;
        }
        if (depositAmount > 0) {
            const depositToWei = ethers.utils.parseUnits(depositAmount);

            try {
                const allowance = await slgoContract.allowance(connectedAddress, solalgo.contracts[0].address);

                if (parseInt(allowance.toString()) >= parseInt(depositToWei)) {
                    setIsDepositing(true);
                    
                    try {
                        const transaction = await stakingContract.deposit(depositToWei);

                        await transaction.wait().then(async(result) => {
                            await initialiseData();
                            notify(1, "Successfully deposited " + depositAmount + " SLGO tokens");
                            setIsDepositing(false);
                            setDepositAmount('');
                        });
                    }
                    catch(err) {
                        notify(0, "Error: " + err);
                        setIsDepositing(false);
                    }
                }
                else {
                    approveContract();
                }
            }
            catch(err) {
                notify(0, "Error: " + err);
            }
        }
        else {
            notify(0, "Please enter more than 0 SLGO Tokens");
        }
    };

    const withdrawSLGO = async() => {
        if (!signer) {
            notify(0, "Please connect your wallet first");
            return;
        }

        try {
            if (withdrawAmount > 0) {
                const withdrawToWei = ethers.utils.parseUnits(withdrawAmount);
                setIsWithdrawing(true);

                try {
                    const transaction = await stakingContract.withdraw(withdrawToWei);

                    await transaction.wait().then(async(result) => {
                        await initialiseData();
                        notify(1, "Successfully withdrawn "+ withdrawAmount +" SLGO tokens");
                        setIsWithdrawing(false);
                        setWithdrawAmount('');
                    });
                }
                catch(err) {
                    notify(0, "Error: " + err);
                    setIsWithdrawing(false);
                }
            }
            else {
                notify(0, "Please enter more than 0 SLGO Tokens");
            }
        }
        catch(err) {
            notify(0, "Error: " + err);
        }
    };

    const emergencyWithdraw = async() => {
        if (!signer) {
            notify(0, "Please connect your wallet first");
            return;
        }

        try {
            setIsEmWithdrawing(true);

            try {
                const transaction = await stakingContract.emergencyWithdraw();

                await transaction.wait().then(async(result) => {
                    await initialiseData();
                    notify(1, "Successfully withdrawn all SLGO tokens");
                    setIsEmWithdrawing(false);
                    setWithdrawAmount('');
                    handleClose();
                });
            }
            catch(err) {
                notify(0, "Error: " + err);
                setIsEmWithdrawing(false);
            }
        }
        catch(err) {
            notify(0, "Error: " + err);
        }
    };

    const emergencyWarn = () => {
        setModalOpen(true);
    };

    const claimRewards = async() => {
        if (!signer) {
            notify(0, "Please connect your wallet first");
            return;
        }

        try {
            setisClaimingRewards(true);

            try {
                const transaction = await stakingContract.deposit(0);

                await transaction.wait().then(async(result) => {
                    await initialiseData();
                    notify(1, "Successfully claimed your rewards");
                    setisClaimingRewards(false);
                });
            }
            catch(err) {
                notify(0, "Error: " + err);
                setisClaimingRewards(false);
            }

        }
        catch(err) {
            notify(0, "Error: " + err);
        }
    };

    const approveContract = async() => {
        setIsApproving(true);
        
        try {
            const approveTransaction = await slgoContract.approve(solalgo.contracts[0].address, "115792089237316195423570985008687907853269984665640564039457584007913129639935");
            await approveTransaction.wait();
            setIsApproving(false);
            notify(1, "Successfully approved. You can now deposit your tokens");
        }
        catch(err) {
            setIsApproving(false);
        }
    };

    const notify = (flag, msg, delay = 10000) => {
        let type = "error";
  
        if (flag === 1) {
            type = "success";
        }
  
        enqueueSnackbar(msg, { variant: type, autoHideDuration: delay, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
        }});
    };

    const convertTimestampToDate = (date) => {
        let dateStr = date.toString();
        var t = new Date(parseInt(dateStr) * 1000);
        return t.toLocaleString();
    };

    const initialiseData = async() => {
        try {
            const balance = await slgoContract.balanceOf(connectedAddress);
            const balanceParsed = ethers.utils.formatEther(balance, 'ether');
            setSLGOBalance(parseFloat(balanceParsed).toFixed(2));

            const apr = await stakingContract.apr();
            setAPR(parseInt(apr.toString()));

            const currentTier = await stakingContract.getTier(connectedAddress);
            setCurrentTier(parseInt(currentTier.toString()));

            const totalEarned = await stakingContract.getAccountTotalEarned(connectedAddress);
            const totalEarnedParsed = ethers.utils.formatEther(totalEarned.toString(), 'ether');
            setTotalEarned(parseFloat(totalEarnedParsed).toFixed(4));

            const pendingReward = await stakingContract.pendingReward(connectedAddress);
            const pendingRewardParsed = ethers.utils.formatEther(pendingReward.toString(), 'ether');
            setPendingReward(parseFloat(pendingRewardParsed).toFixed(4));

            const dividendBalance = await stakingContract.dividendTokenBalanceOf(connectedAddress);
            const dividendBalanceParsed = ethers.utils.formatEther(dividendBalance.toString(), 'ether');
            setDividendBalance(parseFloat(dividendBalanceParsed).toFixed(0));

            const pendingDividend = await stakingContract.withdrawableDividendOf(connectedAddress);
            const pendingDividendParsed = ethers.utils.formatEther(pendingDividend, 'ether');
            setPendingDividend(parseFloat(pendingDividendParsed).toFixed(4));

            const holderUnlockTime = await stakingContract.holderUnlockTime(connectedAddress);
            setHolderUnlockTime(parseInt(holderUnlockTime.toString()));

            const exitPenalty = await stakingContract.exitPenaltyPerc();
            setExitPenalty(parseInt(exitPenalty.toString()));
            
            if (parseInt(holderUnlockTime) > 0) {
                setInterval(() => {
                    const currentTime = new Date().getTime();
                    const unlockTime = new Date(parseInt(holderUnlockTime + "100")).getTime();
                    const isUnlocked = currentTime >= unlockTime ? true : false;
                    
                    const countdown = () => {
                        const timeRemaining = unlockTime - currentTime;
                
                        let seconds = Math.floor(timeRemaining / 1000);
                        let minutes = Math.floor(seconds / 60);
                        let hours = Math.floor(minutes / 60);
                        let days = Math.floor(hours / 24);
                
                        seconds %= 60;
                        minutes %= 60;
                        hours %= 24;
                
                        setUnlockState((prevState) => ({
                            ...prevState,
                            seconds,
                            minutes,
                            hours,
                            days,
                            isUnlocked,
                            initalised: true
                        }));
                    };

                    if (!isUnlocked) {
                        countdown(); 
                    }
                    else {
                        setUnlockState((prevState) => ({
                            ...prevState,
                            isUnlocked: true
                        }));
                    }
                }, 1000);
            }
        } 
        catch(err) {
            console.log(err);
        }
    };

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") 
          return;
        setModalOpen(false);
    };  

    useEffect(() => {
        if (connectedAddress) {
            stakingContract = new ethers.Contract(solalgo.contracts[0].address, JSON.parse(solalgo.contracts[0].abi), signer);
            slgoContract = new ethers.Contract(solalgo.contracts[1].address, JSON.parse(solalgo.contracts[1].abi), signer);
            initialiseData();
        }
    }, [signer, connectedAddress]);

    signer = useSigner();
    connectedAddress = useAddress();

    return(
        <>
            <Container maxWidth="lg" className={classes.mainContainer}>
                <Fragment>
                    <Grid container spacing={2} className='stakingContainer'>
                        <Grid item xs={12} md={8}>
                            <Paper className={classes.paperContainer}>
                                <Typography variant="h4" className='orange'>
                                    30-Day Locked SLGO/BNB Staking Pool
                                </Typography>
                                <Typography variant="h6" className={classes.smallTxt2}>
                                    The staking pool incorporates multiple dividend trackers to accommodate the five tiers effectively
                                </Typography>
                                <Grid className={classes.statisticContainer} container spacing={2}>
                                    <Grid item md={3} xs={6}>
                                        <Typography variant="h6" className={classes.statistic}>
                                            Pool<br/>
                                            <span className='orange'>SLGO/BNB</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3} xs={6}>
                                        <Typography variant="h6" className={classes.statistic}>
                                            Current Tier<br/>
                                            <span className='orange'>{currentTier ? 'Tier ' + currentTier : '---'}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3} xs={6}>
                                        <Typography variant="h6" className={classes.statistic}>
                                            Current APY<br/>
                                            <span className='orange'>{apr ? apr + "%" : "---"}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3} xs={6}>
                                        <Typography variant="h6" className={classes.statistic}>
                                            SLGO Balance<br/>
                                            <span className='orange'>{slgoBalance >= 0 ? slgoBalance + " SLGO": "---"}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid className={classes.inputContainer} container spacing={2}>
                                    <Grid item md={9} xs={12}>
                                        <TextField fullWidth variant="outlined" name="inputAmount" type="number" placeholder="Enter SLGO Amount" onChange={e => setDepositAmount(e.target.value)} value={depositAmount ? depositAmount : ''} InputProps={{style: {borderRadius: "15px"} }}/>
                                    </Grid>
                                    <Grid item md={3} xs={12} className='centered'>
                                        <Button startIcon={isApproving || isDepositing ? <CircularProgress size="1rem" style={{'color': '#fff'}} /> : null} variant="contained" disabled={isApproving || isDepositing ? true : false} className="orangeBtn" onClick={() => depositSLGO()}>{isApproving && !isDepositing ? "Approving" : isDepositing && !isApproving ? "Depositing" : "Deposit SLGO"}</Button>
                                    </Grid>
                                </Grid>
                            </Paper>

                            {unlockTime > 0 ? 
                                <Paper className={classes.rewardContainer}>
                                    <Typography variant="h4" className='orange'>
                                        Total Rewards
                                    </Typography>
                                    <Typography variant="h6" className={classes.smallTxt2}>
                                        Please Note: Dividend payouts are made in BNB and staking rewards are made in SLGO
                                    </Typography>
                                    <Grid className={classes.statisticContainer} container spacing={2}>
                                        <Grid item md={3} xs={6}>
                                            <Typography variant="h6" className={classes.statistic}>
                                                Staked Amount<br/>
                                                <span className='orange'>{dividendBalance >= 0 ? dividendBalance + ' SLGO' : "---"}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={3} xs={6}>
                                            <Typography variant="h6" className={classes.statistic}>
                                                Total Dividends<br/>
                                                <span className='orange'>{totalEarned > -1 ? totalEarned + ' BNB' : '---'}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={3} xs={6}>
                                            <Typography variant="h6" className={classes.statistic}>
                                                Pending Rewards<br/>
                                                <span className='orange'>{pendingReward >= 0 ? pendingReward + ' SLGO' : '---'}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={3} xs={6}>
                                            <Typography variant="h6" className={classes.statistic}>
                                                Pending Dividends<br/>
                                                <span className='orange'>{pendingDividend >= 0 ? pendingDividend + ' BNB' : '---'}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Button variant="contained" startIcon={isClaimingRewards ? <CircularProgress size="1rem" style={{'color': '#fff'}} /> : null} className='claimBtn' onClick={() => claimRewards()}>{isClaimingRewards ? "Claiming" : "Claim Rewards"}</Button>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.unlockContainer}>
                                        <Typography variant='h6'>Unlock Date: {convertTimestampToDate(unlockTime)}</Typography>
                                        {unlockState.initalised ? 
                                            <Grid className={classes.timerContainer}>
                                                <Countdown countdownData={unlockState} />
                                            </Grid>
                                        : ""}
                                    </Grid>
                                    <Grid className={classes.inputContainer} container spacing={2}>
                                        <Grid item md={9} xs={12}>
                                            <TextField fullWidth variant="outlined" name="outputAmount" type="number" placeholder="Enter SLGO Amount" onChange={e => setWithdrawAmount(e.target.value)} value={withdrawAmount ? withdrawAmount : ''} InputProps={{style: {borderRadius: "15px"} }}/>
                                        </Grid>
                                        <Grid item md={3} xs={12} className='centered'>
                                            <Button startIcon={isWithdrawing ? <CircularProgress size="1rem" style={{'color': '#fff'}} /> : null} variant="contained" disabled={unlockState.isUnlocked ? false : true} className={unlockState.isUnlocked ? 'orangeBtn' : 'orangeBtn btnDisabled'} onClick={() => withdrawSLGO()}>{isWithdrawing ? "Withdrawing" : "Withdraw SLGO"}</Button>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Button variant="contained" className='emergencyBtn' onClick={() => emergencyWarn()}>{"Emergency Withdraw (" + exitPenalty + "% Penalty)"}</Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            : ''}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper className={classes.tierContainer}>
                                <Typography variant="h4" className='orange'>
                                    Dividend Tiers
                                </Typography>
                                <Typography variant="h6" className={classes.smallTxt}>
                                    Please Note: Be aware that stakers are subjected to penalties through the emergency withdrawal fee if they attempt to remove tokens prior to the end of the lock-up period
                                </Typography>
                                <Grid className={classes.statisticContainer} container spacing={2}>
                                    <Grid item md={12} xs={12}>
                                        <Typography variant="h6"  className={currentTier === 1 ? 'tier selectedTier' : 'tier'}>
                                            <span className='orange'>Tier 1</span><br/>
                                            - 100,000 SLGO Minimum<br/>
                                            - Receive 4% APY<br/>
                                            - 30% Dividend Payout
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography variant="h6" className={currentTier === 2 ? 'tier selectedTier' : 'tier'}>
                                            <span className='orange'>Tier 2</span><br/>
                                            - 50,000 SLGO Minimum<br/>
                                            - Receive 4% APY<br/>
                                            - 25% Dividend Payout
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography variant="h6" className={currentTier === 3 ? 'tier selectedTier' : 'tier'}>
                                            <span className='orange'>Tier 3</span><br/>
                                            - 25,000 SLGO Minimum<br/>
                                            - Receive 4% APY<br/>
                                            - 20% Dividend Payout
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12} >
                                        <Typography variant="h6" className={currentTier === 4 ? 'tier selectedTier' : 'tier'}>
                                            <span className='orange'>Tier 4</span><br/>
                                            - 10,000 SLGO Minimum<br/>
                                            - Receive 4% APY<br/>
                                            - 15% Dividend Payout
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography variant="h6" className={currentTier === 5 ? 'tier selectedTier' : 'tier'}>
                                            <span className='orange'>Tier 5</span><br/>
                                            - Any SLGO Amount<br/>
                                            - Receive 4% APY<br/>
                                            - 10% Dividend Payout
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Dialog open={modalOpen} onClose={handleClose} className="result-dialog">
                                    <div className={classes.modal}>
                                        <DialogTitle>Emergency Withdraw Warning</DialogTitle>
                                        <DialogContent>
                                            Please Note: This will withdraw your total staked SLGO. The entire amount will be subject to the early withdrawal fee of {exitPenalty}%. 
                                            <br/>
                                            <br/>
                                            <br/>
                                            Are you sure you want to continue?
                                            <br/>
                                            <br/>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button disabled={isEmWithdrawing ? true : false} variant="contained" className="infoBtn noMargin" onClick={() => handleClose()}>{"Close"}</Button>
                                            <Button startIcon={isEmWithdrawing ? <CircularProgress size="1rem" style={{'color': '#fff'}} /> : null} variant="contained" className='emergencyBtn noMargin' onClick={() => emergencyWithdraw()}>{isEmWithdrawing ? "Withdrawing" : "Emergency Withdraw"}</Button>
                                        </DialogActions>
                                    </div>
                                </Dialog>
                            </Paper>
                        </Grid>
                    </Grid>
                </Fragment>
            </Container>
        </>
    );
}