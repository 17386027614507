import { ConnectWallet } from "@thirdweb-dev/react";
import { useProSidebar } from "react-pro-sidebar";
import MenuOpen from "@material-ui/icons/MenuOpen";
import Menu from "@material-ui/icons/Menu";
import React, { useContext } from "react";
import "./index.css";

const TaskBar = (props) => {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken } = useProSidebar();

    const toggleHandler = () => {
        if (broken) {
            toggleSidebar();
        }
        else {
            collapseSidebar();
        }
    }

    return (
        <div className="Header">
            <div className="logo-text">
                <div className="expand-menu">
                    {collapsed || broken ? <Menu onClick={toggleHandler}></Menu> : <MenuOpen onClick={toggleHandler}></MenuOpen>}
                </div>

                <div className="connect-container">
                    <ConnectWallet className="connect-btn orangeBtn" theme="dark" dropdownPosition={{ side: 'bottom', align: 'center'}} />
                </div>
            </div>
        </div>
    )
};

export default TaskBar;